import styled from 'styled-components'
import { Link } from 'gatsby'

import {
  mobileVW,
  desktopVW,
  font,
  colors,
  letterSpacing,
  desktopBreakpoint,
  quad,
} from '../styles'

const Button = styled(Link)`
  display: inline-block;
  font-family: ${font.extended};
  font-size: ${mobileVW(8)};
  letter-spacing: ${letterSpacing(6)};
  text-transform: uppercase;
  //border-bottom: 1px solid white;
  //padding: ${mobileVW(12)} 0 ${mobileVW(14)};
  color: ${colors.white};
  font-weight: 800;
  position: relative;

  

  &::after {
    content: '';
    display: block;
    margin-top: ${mobileVW(10)};
    height: 1px;
    background: ${colors.white};
    transform: scaleX(1);
    transition: transform 0.3s ${quad.inOut};
    transform-origin: left;


    ${desktopBreakpoint} {
      margin-top: ${desktopVW(10)};
    }
  }


  &:hover::after {
    transform: scaleX(0);
  }

  ${desktopBreakpoint} {
    font-size: ${desktopVW(10)};
  }
`

export default Button
