import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import Image from 'gatsby-image'
import BigHeading from '../components/BigHeading'
import Button from '../components/Button'
import { mobileVW, desktopVW, font, colors, desktopBreakpoint } from '../styles'
import RichText from '../components/RichText'
import SEO from '../components/SEO'
import { useRecoilValue } from 'recoil'
import { startPageAnimation } from '../atoms'

const StyledContact = styled.section`
  margin: ${desktopVW(250)} 0 ${desktopVW(200)};
`

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${desktopVW(250)} 0 ${desktopVW(150)};

  a {
    margin: 0 ${desktopVW(40)};
  }
`

const Office = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${mobileVW(30)};
  ${desktopBreakpoint} {
    flex-direction: row;
    margin-left: ${desktopVW(120)};
  }
`
const Location = styled.div`
  flex: 0 0 30%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-size: ${mobileVW(16)};
  line-height: ${mobileVW(25)};
  margin-bottom: ${mobileVW(16)};
  p {
    ${desktopBreakpoint} {
      font-size: ${desktopVW(16)};
      line-height: ${desktopVW(25)};
    }
  }
  b {
    font-size: ${mobileVW(12)};
    line-height: ${mobileVW(22)};
    font-weight: 800;
    font-family: ${font.extended};
    display: block;
    text-transform: uppercase;
    ${desktopBreakpoint} {
      font-size: ${desktopVW(12)};
      line-height: ${desktopVW(22)};
    }
  }
  ${desktopBreakpoint} {
    margin-bottom: 0;
  }
`
const ImageWrapper = styled.div`
  flex: 0 0 70%;
`
const Contact = ({
  data: {
    contentfulContactPage: {
      title,
      buttonInvestor,
      buttonVenture,
      officeDetails,
      officeImage,
    },
  },
}) => {
  const appear = useRecoilValue(startPageAnimation)
  return (
    <>
      <SEO title={`CONTACT | ASK PHILL VENTURES`} />

      <StyledContact>
        <BigHeading visible={appear}>{title}</BigHeading>
        <ButtonWrapper>
          <Button to='/get-in-touch'>{buttonInvestor}</Button>
          <Button to='/get-in-touch'>{buttonVenture}</Button>
        </ButtonWrapper>
        <Office>
          <Location>
            <RichText json={officeDetails.json} />
          </Location>
          <ImageWrapper>
            <Image
              fluid={officeImage.fluid}
              backgroundColor={colors.backgroundImage}
            />
          </ImageWrapper>
        </Office>
      </StyledContact>
    </>
  )
}

export const PageQuery = graphql`
  query Contact($id: String!) {
    contentfulContactPage(id: { eq: $id }) {
      title
      buttonInvestor
      buttonVenture
      officeDetails {
        json
      }
      officeImage {
        fluid(maxWidth: 800) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
  }
`

export default Contact
